<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">

          <h1>
            <div class="download-menu">

              <div class="download-menu-icon">
                <svg
                    @click="exportChecklist"
                    fill="none"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M8 17V15H16V17H8ZM16 10L12 14L8 10H10.5V7H13.5V10H16ZM5 3H19C20.11 3 21 3.9 21 5V19C21 20.11 20.11 21 19 21H5C3.9 21 3 20.11 3 19V5C3 3.9 3.9 3 5 3ZM5 5V19H19V5H5Z"
                      fill="#0033A0"
                  />
                </svg>
              </div>
            </div>
          </h1>

          <p>Полное название центра занятости населения: {{ $regionAndDepartmentInfo.department?.name }}</p>
          <p>Адрес: {{ $regionAndDepartmentInfo.department?.address }}</p>
          <p>Уровень центра занятости населения: {{
              $regionAndDepartmentInfo.department?.type === 'territorial_czn' ? 'территориальный' : $regionAndDepartmentInfo.department?.type === 'manager_czn' ? 'управляющий' : ''
            }}</p>
          <p v-if="$regionAndDepartmentInfo.department?.type==='territorial_czn'">Тип территориального центра занятости населения:
            <template v-if="$regionAndDepartmentInfo.department?.level == 1">первый уровень</template>
            <template v-else-if="$regionAndDepartmentInfo.department?.level == 2">второй уровень</template>
            <template v-else-if="$regionAndDepartmentInfo.department?.level == 3">третий уровень</template>
            <template v-else><span style="color: red">Уровень ЦЗН не указан. Для корректного отображения списка вопросов необходимо указание уровня региональным администратором</span></template>
          </p>

          <div class="list passport">
            <div v-for="(group, group_idx) of config.groups" :key="group_idx+'_gro'" class="list-item-wrap">
              <v-expansion-panels>
                <check-list-group
                    :group-config="group"
                    :domain="domain"
                    :region-and-department-info="$regionAndDepartmentInfo"
                    :period="period"
                    :period-span="periodSpan"
                    depth="0"
                    :prefix="group_idx+1+'.'"
                    :values="values"
                    :controls="controls"
                    :controller-roles="controllerRoles"
                    :editor-roles="editorRoles"
                    @updated="cclEditDialogUpdated"
                ></check-list-group>
              </v-expansion-panels>
            </div>
          </div>

        </div>
        <LoaderDialog :show="showLoaderDialog"></LoaderDialog>
      </div>
      <div v-else style="height:1000px"></div>
    </transition>
  </div>
</template>


<script>

import {filterConfig, loadConfigByPeriod} from "@/modules/IccRepositoryNew";
import LoaderDialog from "@/components/elements/LoaderDialog";
import CheckListGroup from "@/components/icc_new/CheckListGroup";
import {getCznIndicatorsByPeriod} from "@/modules/api.indicators";
import {previousPeriod} from "@/modules/periods";
import {getCznControlsByPeriod} from "@/modules/api.controls";

export default {
  name: "ClientCentricityCheckList",
  components: {CheckListGroup, LoaderDialog},
  props: ['domain', 'period', 'periodSpan'],

  data: () => ({
    values: [],
    controls: [],
    controllerRoles: ['ROLE_ADMIN', 'ROLE_VNII'],
    editorRoles: ['ROLE_ADMIN', 'ROLE_VNII'],
    groups: {}, // группы показателей из конфига
    showLoaderDialog: false,
    apiLoaded: false,
    errorText: null,
    dialog: false,
  }),

  computed: {
  },

  methods: {
    async exportChecklist(){

    },

    async getActiveConfig() {
      this.loadedConfig = await loadConfigByPeriod(this.period)
      this.config = filterConfig(this.loadedConfig,
          this.$regionAndDepartmentInfo.region.netType,
          this.$regionAndDepartmentInfo.department.type,
          this.$regionAndDepartmentInfo.department.level
      )
    },

    cclEditDialogUpdated(){
      this.initialize()
    },

    async initialize() {
      await this.getActiveConfig()
      const [reqValsCurr, reqValsPrev, reqControls] = await Promise.all([
        getCznIndicatorsByPeriod(this.domain, this.$regionAndDepartmentInfo.department.id, this.period), // данные текущего периода
        getCznIndicatorsByPeriod(this.domain, this.$regionAndDepartmentInfo.department.id, previousPeriod(this.period, this.periodSpan)), // данные предшествующего периода
        getCznControlsByPeriod(this.domain, this.$regionAndDepartmentInfo.department.id, this.period)
      ])
      if (reqValsCurr.ok && reqValsPrev.ok && reqControls.ok) {
        this.values = reqValsCurr.payload
        this.values = this.values.concat(reqValsPrev.payload)
        this.controls = reqControls.payload
      } else {
        alert('Ошибка загрузки данных')
      }
      this.apiLoaded = true
      this.$forceUpdate()
    }
  },

  filters: {

  },

  watch: {
    $regionAndDepartmentInfo: {
      async handler() {
        await this.initialize()
      },
      deep: true
    },
    period: {
      async handler() {
        await this.initialize()
      }
    }
  },

  async beforeMount() {
    await this.initialize()
    this.apiLoaded = true;
  },
}
</script>

<style scoped lang="scss">
h1 {
  display: flex;
  align-items: center;

  .badge {
    width: 152px;
    height: 60px;
    background: #E8E8E9;
    border: 1px solid #E8E8E9;
    border-radius: 4px;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 70px;
    color: $cblack;
  }

  .v-progress-circular {
    margin-left: 20px;
  }

  .v-btn {
    margin-left: auto;
  }
}

.manual-link {
  margin: 20px 0;

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-bottom: 20px;

    .v-icon {
      color: #0033a0;
      margin-right: 10px;
    }
  }
}

.list {
  table {
    margin-bottom: 25px;

    th {
      border-bottom: 1px solid #E8E8E9;

      div {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }

    td:nth-child(2),
    th:nth-child(2) {
      text-align: right;
    }

    td > div {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;

      svg {
        margin-right: 8px;
        flex-shrink: 0;
        margin-top: 5px;
      }
    }

    tr:last-child td {
      border-bottom: 1px solid #E8E8E9;
    }
  }
}

.push-right {
  float: right;
  margin-top: 15px;
}
</style>

<style lang="scss">
@import "../../styles/main";
</style>

<style>
.v-progress-circular__info {
  font-size: 13px !important;
  text-align: center;
  width: 100%;
}
</style>
