<template>
  <v-card>
    <v-card-text>
      <h3>Данные за {{ getPeriodName(period) }}</h3>
      <h3>{{ config.title }}</h3>
      <div class="list">
        <table cellpading="0">
          <thead>
          <tr>
            <th colspan="2">Параметр</th>
            <th colspan="2">Контроль</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(question, questionIdx) in config.questions">
            <tr v-if="question.type==='COMMENT'" :key="question.id">
              <td style="vertical-align: top; width: 400px">{{
                  prefix
                }}{{ (config?.groups?.length || 0) + 1 + questionIdx }}. {{ question.title }}
              </td>
              <td style="width: 450px">
                <v-textarea
                    placeholder="Комментарий"
                    :value="getValue(question.id)"
                    :disabled="!editorRoles.includes($user.role)"
                    @change="setValue($event, question.id)"
                ></v-textarea>


                <template v-for="file in getAttachments(question.id)">
                  <div :key="file.id + 'fileIndex'" class="d-flex align-center">
                    <svg width="10" height="20" viewBox="0 0 10 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                         class="mr-2">
                      <path
                          d="M8.94029 5.78581C8.59471 5.78581 8.31597 6.05336 8.31597 6.39919V15.4626C8.31597 16.388 7.98158 17.146 7.33488 17.7927C6.68819 18.4394 5.91905 18.7738 4.98271 18.7738C4.06847 18.7738 3.28814 18.4505 2.64147 17.8039C1.99481 17.1572 1.67158 16.3769 1.67158 15.4626V12.0402V10.2119V5.7078V3.54518V3.5228V3.50045C1.67158 2.88732 1.89728 2.35505 2.32943 1.91742C2.76705 1.47434 3.29908 1.23745 3.93481 1.23745C4.57031 1.23745 5.10259 1.47434 5.53993 1.91742C5.97208 2.35505 6.19781 2.88732 6.19781 3.50045V3.5228V3.5673V13.6791C6.19781 14.3143 5.70738 14.805 5.0719 14.805C4.45875 14.805 3.95693 14.2922 3.95693 13.6791V8.38343C3.95693 8.03785 3.68935 7.7703 3.3438 7.7703C2.99824 7.7703 2.73067 8.03785 2.73067 8.38343V13.6791C2.73067 14.3367 2.95375 14.8942 3.41064 15.3513C3.86776 15.8084 4.41408 16.0313 5.07193 16.0313C5.72953 16.0313 6.28152 15.8027 6.74413 15.3513C7.19579 14.9106 7.42413 14.3591 7.42413 13.6791V3.5228V3.50045C7.42413 2.54174 7.07569 1.71955 6.39857 1.03672C5.71572 0.348182 4.89352 0 3.93481 0C2.97587 0 2.15367 0.348182 1.47084 1.03672C0.793747 1.71955 0.445312 2.54174 0.445312 3.50045V3.5228V5.7078V10.2119V12.0402V15.4626C0.445312 16.7224 0.891247 17.7927 1.77195 18.6733C2.65266 19.554 3.72289 20 4.98271 20C6.24254 20 7.33488 19.5764 8.22678 18.6845C9.11865 17.7927 9.55342 16.7003 9.55342 15.4626V6.39919C9.55342 6.05336 9.28584 5.78581 8.94029 5.78581Z"
                          fill="#777779"/>
                    </svg>
                    <a :href="getFileUrl(file.id)" @click.prevent="downloadAttachment(file.id, file.name)">
                      {{ truncateStr(file.name, 30) }}
                    </a>
                    <a @click="deleteFile(question.id, file.id)" v-if="editorRoles.includes($user.role)">
                      <v-icon class="icon-item icon-window-close"></v-icon>
                    </a>
                  </div>
                  <div class="d-flex align-left" :key="file.id + 'fileIndex2'" style="color:black">
                        <span v-if="!previewCoordinates(file)">
                          Координаты: отсутствуют
                        </span>
                    <a target="_blank"
                       :href="'https://yandex.ru/maps/213/moscow/?mode=search&text=' + previewCoordinates(file)"
                       v-else>
                      {{ previewCoordinates(file) }}
                    </a>

                  </div>
                  <div class="d-flex align-left" :key="file.id + 'fileIndex3'">
                    <a :href="getFileUrl(file.id)" target="_blank">
                      <img :src="getFileUrl(file.id)"
                           :ref="'preview' + file.id"
                           style="max-height:130px; max-width: 250px;"
                      />
                    </a>
                  </div>
                </template>
                <div class="d-flex align-center" v-if="editorRoles.includes($user.role)">
                  <input type="file" multiple :ref="'fileupload'+question.id" style="display:none"
                         @change="addFiles($event.target.files, question.id)">
                  <a @click="$refs['fileupload'+question.id][0].click()" class="upload-link"><i>Добавить файлы</i></a>
                </div>
              </td>
                <td style="width: 10px; padding-right: 10px; padding-top: 90px">
                  <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"
                       fill="green">
                    <circle cx="7" cy="7" r="7"/>
                  </svg>
                  <br/>
                  <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"
                       fill="yellow">
                    <circle cx="7" cy="7" r="7"/>
                  </svg>
                  <br/>
                  <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"
                       fill="red">
                    <circle cx="7" cy="7" r="7"/>
                  </svg>
                </td>
                <td style="padding-top: 90px;">
                  <v-radio-group
                      :disabled="!controllerRoles.includes($user.role)"
                      :value="getControl(question.id)?.flag"
                      @change="setControlFlag($event, question.id)"
                  >
                    <v-radio value="GREEN" style="width: 0; height: 20px" />
                    <v-radio value="YELLOW" style="width: 0; height: 20px" />
                    <v-radio value="RED" style="width: 0; height: 20px" />
                  </v-radio-group>
                </td>
                <td style="width: 300px;">
                  <v-textarea
                      :disabled="!controllerRoles.includes($user.role)"
                      placeholder="Примечание"
                      :value="getControl(question.id)?.comment"
                      @change="setControlComment($event, question.id)"
                  ></v-textarea>
                  <v-text-field
                      :disabled="!controllerRoles.includes($user.role)"
                      placeholder="Оценка"
                      type="number"
                      :value="getControl(question.id)?.score"
                      @change="setControlScore($event, question.id)"
                  ></v-text-field>
                </td>
            </tr>
            <template v-else-if="question.type==='DYNAMIC'">
              <tr :key="question.id+'cur'">
                <td style="vertical-align: top; width: 400px">{{
                    prefix
                  }}{{ (config?.groups?.length || 0) + 1 + questionIdx }}. {{ question.title }} (Данные за текущий
                  период)
                </td>
                <td style="width: 450px">
                  <v-text-field
                      placeholder="Значение"
                      type="number"
                      :value="getValue(question.id)"
                      @change="setValue($event, question.id)"
                      :disabled="!editorRoles.includes($user.role)"
                  ></v-text-field>
                </td>
                  <td style="width: 10px; padding-right: 10px; padding-top: 90px">
                    <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"
                         fill="green">
                      <circle cx="7" cy="7" r="7"/>
                    </svg>
                    <br/>
                    <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"
                         fill="yellow">
                      <circle cx="7" cy="7" r="7"/>
                    </svg>
                    <br/>
                    <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"
                         fill="red">
                      <circle cx="7" cy="7" r="7"/>
                    </svg>
                  </td>
                  <td style="padding-top: 90px;">
                    <v-radio-group
                        :disabled="!controllerRoles.includes($user.role)"
                        :value="getControl(question.id)?.flag"
                        @change="setControlFlag($event, question.id)"
                    >
                      <v-radio value="GREEN" style="width: 0; height: 20px" />
                      <v-radio value="YELLOW" style="width: 0; height: 20px" />
                      <v-radio value="RED" style="width: 0; height: 20px" />
                    </v-radio-group>
                  </td>
                  <td style="width: 300px;">
                    <v-textarea
                        :disabled="!controllerRoles.includes($user.role)"
                        placeholder="Примечание"
                        :value="getControl(question.id)?.comment"
                        @change="setControlComment($event, question.id)"
                    ></v-textarea>
                    <v-text-field
                        :disabled="!controllerRoles.includes($user.role)"
                        placeholder="Оценка"
                        type="number"
                        :value="getControl(question.id)?.score"
                        @change="setControlScore($event, question.id)"
                    ></v-text-field>
                  </td>
              </tr>
              <tr :key="question.id+'prev'">
                <td style="vertical-align: top; width: 400px"> {{ question.title }} (Данные за предыдущий
                  период)
                </td>
                <td style="width: 450px">
                  <v-text-field
                      placeholder="Значение"
                      type="number"
                      :value="getValue(question.id, previousPeriod(period, periodSpan))"
                      @change="setValue($event, question.id, previousPeriod(period, periodSpan))"
                      :disabled="!editorRoles.includes($user.role)"
                  ></v-text-field>
                </td>
                <td></td>
              </tr>
            </template>
          </template>
          </tbody>
        </table>
      </div>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
          class="btn btn-outline"
          text
          @click="$emit('close')">
        Отмена
      </v-btn>
      <v-btn
          class="btn btn-primary"
          :disabled="submitButtonDisabled"
          @click="submit()">
        Сохранить изменения
      </v-btn>
    </v-card-actions>
    <div v-if="errorText!=null" class="error" style="position: fixed;bottom: 0">
      Ошибка: {{ errorText }}
    </div>
    <LoaderDialog :show="showLoaderDialog" msg="Загрузка файлов"></LoaderDialog>
  </v-card>
</template>

<script>
import LoaderDialog from "@/components/elements/LoaderDialog";
import {getPeriodName, previousPeriod} from "@/modules/periods";
import {getCznIndicatorsByPeriod, saveIndicators} from "@/modules/api.indicators";
import {downloadFile, uploadFile} from "@/modules/api.files";
import EXIF from "exif-js";
import {truncateStr} from "@/modules/CommonUtils";
import {getCznControlsByPeriod, saveControls} from "@/modules/api.controls";

export default {
  name: "ClientCentricityEditDialog",
  props: ['config', 'domain', 'period', 'periodSpan', 'prefix', 'controllerRoles', 'editorRoles'],
  components: {LoaderDialog},
  data: () => ({
    values: [],
    controls: [],
    showLoaderDialog: false,
    apiLoaded: false,
    errorText: null,
    submitButtonDisabled: false
  }),

  methods: {
    getPeriodName: getPeriodName,
    previousPeriod: previousPeriod,
    truncateStr: truncateStr,
    getValue(questionId, period) {
      if (period == null) {
        period = this.period
      }
      return this.values.find(e => e.indicatorId === questionId && e.period === period && e.type === 'VALUE')?.value
    },

    deleteValue(questionId, period) {
      if (period == null) {
        period = this.period
      }
      for (let i = 0; i < this.values.length; i++) {
        if (this.values[i].indicatorId === questionId && this.values[i].period === period && this.values[i].type === 'VALUE') {
          this.values.splice(i, 1)
        }
      }
    },

    setValue(value, questionId, period) {
      if (period == null) {
        period = this.period
      }
      this.deleteValue(questionId, period)
      this.values.push({
        indicatorId: questionId,
        regionCode: this.$regionAndDepartmentInfo.region.code,
        cznId: this.$regionAndDepartmentInfo.department.id,
        period: period,
        value: value,
        type: 'VALUE',
        _changed: true // на бэке поле отбрасывается. нужно для фильтрации перед отправкой в submit
      })
      this.$forceUpdate()
    },

    addAttachment(fileId, fileName, questionId, coords) {
      // find existing or create new value of type FILES
      let filesValue = this.values.find(e => e.indicatorId === questionId && e.period === this.period && e.type === 'FILES')
      if (!filesValue) {
        filesValue = {
          indicatorId: questionId,
          regionCode: this.$regionAndDepartmentInfo.region.code,
          cznId: this.$regionAndDepartmentInfo.department.id,
          period: this.period,
          value: '[]',
          type: 'FILES',
        }
        this.values.push(filesValue)
      }
      // add new attachment to files
      const attachmentInfo = {
        id: fileId,
        name: fileName,
      }
      if (coords) {
        attachmentInfo['coords'] = coords
      }
      const valueParsed = JSON.parse(filesValue.value)
      valueParsed.push(attachmentInfo)
      filesValue.value = JSON.stringify(valueParsed)
      filesValue._changed = true
    },

    getFileUrl(fileId){
      return this.$backendUrl + '/files/' + fileId
    },

    checkRoles(roles) {
      return roles.includes(this.$user.role)
    },

    getAttachments(questionId) {
      let filesValue = this.values.find(e => e.indicatorId === questionId && e.period === this.period && e.type === 'FILES')
      if(!filesValue){
        return []
      }
      return JSON.parse(filesValue.value)
    },

    async downloadAttachment(fileId, fileName) {
      this.showLoaderDialog = true
      await downloadFile(fileId, fileName)
      this.showLoaderDialog = false
    },

    deleteFile(questionId, fileId) {
      const filesValue = this.values.find(e => e.indicatorId === questionId && e.period === this.period && e.type === 'FILES')
      const valueParsed = JSON.parse(filesValue.value)
      valueParsed.splice(valueParsed.findIndex(e=>e.id===fileId), 1)
      filesValue.value = JSON.stringify(valueParsed)
      filesValue._changed = true
    },


    //parameter "file" from <input type="file">
    async getGPSCoordinatesFromExif(file) {
      const thisObj = this
      return new Promise((resolve) => {
        EXIF.getData(file, function () {
          const GPSLatitude = EXIF.getTag(this, "GPSLatitude");
          const GPSLatitudeRef = EXIF.getTag(this, "GPSLatitudeRef");
          const GPSLongitude = EXIF.getTag(this, "GPSLongitude");
          const GPSLongitudeRef = EXIF.getTag(this, "GPSLongitudeRef");
          if (!GPSLatitude || !GPSLatitudeRef || !GPSLongitude || !GPSLongitudeRef) {
            resolve(null)
          } else {
            resolve([
              Math.round(thisObj.convertDMSToDD(GPSLatitude[0], GPSLatitude[1], GPSLatitude[2], GPSLatitudeRef) * 100000) / 100000,
              Math.round(thisObj.convertDMSToDD(GPSLongitude[0], GPSLongitude[1], GPSLongitude[2], GPSLongitudeRef) * 100000) / 100000
            ])
          }
        });
      })
    },

    convertDMSToDD(degrees, minutes, seconds, direction) {
      let dd = degrees + minutes/60 + seconds/(60*60);
      if (direction === "S" || direction === "W") {
        dd = dd * -1;
      } // Don't do anything for N or E
      return dd;
    },

    async getImageGpsCoords(fileId) {
      return this.getGPSCoordinatesFromExif(this.$refs['preview' + fileId][0])
    },

    previewCoordinates(file) {
      if(!file.coords) return null
      return file.coords[0] + ', ' + file.coords[1]
    },

    async addFiles(files, questionId) {
      this.showLoaderDialog = true
      for (let file of files) {
        const [fileInfo, coords] = await Promise.all([
          uploadFile(file, 100),
          await this.getGPSCoordinatesFromExif(file)
        ])
        await this.addAttachment(fileInfo.id, fileInfo.name, questionId, coords)
      }
      this.showLoaderDialog = false
    },

    getControl(questionId){
      return this.controls.find(e => e.indicatorId === questionId && e.period === this.period)
    },

    getOrCreateControl(questionId){
      let control = this.getControl(questionId)
      if(!control){
        control = {
          indicatorId: questionId,
          regionCode: this.$regionAndDepartmentInfo.region.code,
          cznId: this.$regionAndDepartmentInfo.department.id,
          period: this.period,
          level: 'VNII',
          _changed: true
        }
        this.controls.push(control)
      }
      return control
    },

    setControlFlag(controlFlag, questionId){
      const control = this.getOrCreateControl(questionId)
      control.flag = controlFlag
      control._changed = true
    },

    setControlComment(controlComment, questionId){
      const control = this.getOrCreateControl(questionId)
      control.comment = controlComment
      control._changed = true
    },

    setControlScore(controlScore, questionId){
      const control = this.getOrCreateControl(questionId)
      control.score = controlScore
      control._changed = true
    },

    // Оставит в this.values только значения по индикаторам с ид в properites.config
    filterValues() {
      const indicatorValidIds = this.config.questions.map(q => q.id)
      this.values = this.values.filter(v => indicatorValidIds.includes(v.indicatorId))
    },

    async submitIndicators(){
      if(!this.editorRoles.includes(this.$user.role)){
        return true
      }

      let req = await saveIndicators(this.values.filter(v => v._changed))
      if (req.ok) {
        return true
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
        this.submitButtonDisabled = false
        return false
      }
    },

    async submitControls(){
      if(!this.controllerRoles.includes(this.$user.role)){
        return true
      }

      let req = await saveControls(this.controls.filter(v => v._changed))
      if (req.ok) {
        return true
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
        this.submitButtonDisabled = false
        return false
      }
    },

    async submit() {
      this.submitButtonDisabled = true
      const [saveIndicatorsOk, saveControlsOk] = await Promise.all([
          this.submitIndicators(),
          this.submitControls()
      ])
      if(saveIndicatorsOk && saveControlsOk){
        this.$emit('updated')
      }

    },
  },
  computed: {},
  async beforeMount() {
    const [reqValsCurr, reqValsPrev, reqControls] = await Promise.all([
      getCznIndicatorsByPeriod(this.domain, this.$regionAndDepartmentInfo.department.id, this.period), // данные текущего периода
      getCznIndicatorsByPeriod(this.domain, this.$regionAndDepartmentInfo.department.id, previousPeriod(this.period, this.periodSpan)), // данные предшествующего периода
      getCznControlsByPeriod(this.domain, this.$regionAndDepartmentInfo.department.id, this.period)
    ])
    if (reqValsCurr.ok && reqValsPrev.ok && reqControls.ok) {
      this.values = reqValsCurr.payload
      this.values = this.values.concat(reqValsPrev.payload)
      this.controls = reqControls.payload
      this.filterValues()
    } else {
      alert('Ошибка загрузки данных')
    }
    this.apiLoaded = true
  }
}
</script>

<style lang="scss" scoped>
table {
  th {
    border-bottom: 1px solid #E8E8E9;

    &:last-child {
      text-align: right;

      a {
        text-decoration: underline;
      }

      .v-btn {
        margin-left: 27px;
      }
    }
  }

  td {
    vertical-align: top;

    &:nth-child(2) {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #FC5252;
      text-align: right;
      vertical-align: top;
    }

    .v-input--selection-controls {
      margin-top: 0;
    }

    > svg {
      margin: 3px 0;

      &:first-child {
        margin-top: 8px;
      }
    }

    .v-subheader {
      height: 64px;
      font-size: 20px;
    }
  }

  .v-textarea textarea {
    height: 60px !important;
    padding: 10px !important;
  }
}

.v-dialog .v-card__actions .btn {
  width: auto !important;
}
</style>
